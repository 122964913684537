const menu = document.getElementsByClassName("navegador_boton")[0]
const logo = document.getElementsByClassName("logo-imagen")[0]
const divLogo = document.getElementsByClassName("navegador_logo")[0]
const menuImagen = document.getElementsByClassName("boton-imagen")[0]
const navegador = document.getElementsByClassName("navegador")[0]
const opciones = document.getElementsByClassName("navegador_opciones")[0]
const links = document.getElementsByClassName("navLink")
var mostrarMenu = 1
document.addEventListener('DOMContentLoaded', function() {
  const scrollBlanco = document.getElementsByClassName('navegadorColor')[0];
  const sectionTop = scrollBlanco.offsetTop;

  window.addEventListener('scroll', function() {
    const scrollY = window.scrollY;
    if (scrollY >= sectionTop) {
      navegador.style.backgroundColor = "rgb(240, 240, 240)";
      logo.src = 'build/img/logoCesco.webp' 
      menuImagen.src = 'build/img/menuIcon.webp'
      links[0].style.color = "black"
      links[1].style.color = "black"
      links[2].style.color = "black"
      links[3].style.color = "black"
      links[4].style.color = "black"
      links[5].style.color = "black"
    }
    else{
      navegador.style.backgroundColor = " rgba(0,0,0,0)"
      logo.src = 'build/img/logos cesco blancoTRANSPARENTE.webp' 
      menuImagen.src = 'build/img/menuBlanco.png'
      links[0].style.color = "white"
      links[1].style.color = "white"
      links[2].style.color = "white"
      links[3].style.color = "white"
      links[4].style.color = "white"
      links[5].style.color = "white"
    }
  });
});
menu.addEventListener("click", function() {
  if(mostrarMenu == 1){

    if(navegador.style.backgroundColor != "rgb(240, 240, 240)"){
      navegador.style.backgroundColor = " rgba(0,0,0,0)"
    }else{
      navegador.style.backgroundColor = "#f0f0f0"
    }
    opciones.style.display = "flex"
    mostrarMenu = 0
  }else{
    if(navegador.style.backgroundColor != "rgb(240, 240, 240)"){
      navegador.style.backgroundColor = " rgba(0,0,0,0)"
    }else{
      navegador.style.backgroundColor = "#f0f0f0"
    }
    opciones.style.display = "none"
    mostrarMenu++
  }

});